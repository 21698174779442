import React from 'react';
import {
    Col, Row, Accordion, Container,
} from 'react-bootstrap';

import { EXTERNAL_LINKS } from '../util/constants';

const FAQComponent = () => (
    <Container id="gs-htg-faq" fluid data-aos="fade-up">
        <Container className="gs-block" data-aos="fade-up">
            <h3 className="text-center pb-3">Frequently Asked Questions</h3>
            <Row>
                <Col md={6}>
                    <Accordion>
                        <Accordion.Toggle eventKey="question">
                            <b>
                                What is a Hackathon and why is this one different?
                            </b>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="question">
                            <>
                                <p>
                                    A hackathon is typically a 24 - 48 hour event where participants (hackers) come in and build a software
                                    or hardware project.
                                </p>
                                <p>
                                    Hack the Globe is more than just a hackathon; it begins with a Kickoff, marking the beginning of the
                                    ideation phase for hackers. During those two weeks, teams will work with a mentor from BCG to formulate
                                    a solution within one of this year’s themes. Finally, teams will build a technical prototype and business
                                    pitch during the Hackathon and present to an expert panel of judges.
                                </p>
                            </>
                        </Accordion.Collapse>
                    </Accordion>
                    <Accordion>
                        <Accordion.Toggle eventKey="question">
                            <b>
                                Will the event be virtual this year? What can I expect from the workshops, activities, and communications?
                            </b>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="question">
                            <>
                                <p>
                                    Hack the Globe 2025 will be hosted in-person at the Toronto and London BCG offices, while continuing to be a fully virtually accessible event.
                                    Toronto and London in-person participants will participate in keynote addresses, networking events, workshops, office hours and the hackathon itself fully in-person.
                                    For participants based outside of Toronto or London, all keynote speakers, topic experts, BCG mentors, sponsors, and judges will be available virtually as well.
                                </p>
                                <p>
                                    When applying, please indicate your timezone and preferred mode of participation.
                                    Once you've applied, please check your email for more information about application status and event logistics.
                                </p>
                            </>
                        </Accordion.Collapse>
                    </Accordion>
                    <Accordion>
                        <Accordion.Toggle eventKey="question">
                            <b>
                                Is there any cost to apply or attend?
                            </b>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="question">
                            <p>
                                There is no cost for participants to apply or attend Hack the Globe.
                                Participants who wish to participate in Toronto or London hubs will incur their own costs for transport.
                            </p>
                        </Accordion.Collapse>
                    </Accordion>
                    <Accordion>
                        <Accordion.Toggle eventKey="question">
                            <b>
                                What are the timelines for applications?
                            </b>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="question">
                            <p>
                                Applications open on December 1st and will close on December 29th at midnight EST.
                            </p>
                        </Accordion.Collapse>
                    </Accordion>
                    <Accordion>
                        <Accordion.Toggle eventKey="question">
                            <b>
                                What are the requirements to apply?
                            </b>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="question">
                            <>
                                <p>
                                    Hack the Globe is open to university students pursuing either an Undergraduate or Masters degree in any
                                    discipline. In fact, last year over 50% of hackers were non-STEM with representation from every year of studies.
                                </p>
                                <p>
                                    Please submit an application
                                    {' '}
                                    <a href={EXTERNAL_LINKS.HTG_APP} target="_blank" rel="noopener noreferrer">here</a>
                                    .
                                </p>
                            </>
                        </Accordion.Collapse>
                    </Accordion>
                    <Accordion>
                        <Accordion.Toggle eventKey="question">
                            <b>
                                How can I prepare before the hackathon begins?
                            </b>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="question">
                            <p>
                                While there is no mandatory preparation before the event, we will share resources on each challenge
                                theme in advance of the Kickoff. We highly encourage your team to review this material and attend
                                the Kickoff prepared with questions to ask with our topic experts and your BCG mentor.
                            </p>
                        </Accordion.Collapse>
                    </Accordion>
                    <Accordion>
                        <Accordion.Toggle eventKey="question">
                            <b>
                                What if I’ve never been to a hackathon before or if I don’t know how to code?
                            </b>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="question">
                            <>
                                <p>
                                    There is no requirement to have previous hackathon experience - in fact, hackathons are a great place to
                                    learn a lot in a short amount of time!
                                </p>
                                <p>
                                    Hack the Globe is all about multidisciplinary approaches to problem solving and we accept a diverse pool
                                    of participants so there is no requirement to know how to code - coding is just one aspect of the event
                                    and successful teams will have members with business, political, and social expertise.
                                </p>
                                <p>
                                    Additionally, we offer a number of workshops during the hackathon weekend that cover technical implementation,
                                    non-profit strategy, presentation skills, and many other topics to round out your team. We encourage students
                                    from all backgrounds to apply!
                                </p>
                            </>
                        </Accordion.Collapse>
                    </Accordion>
                    <Accordion>
                        <Accordion.Toggle eventKey="question">
                            <b>
                                Can I participate if I'm not in Toronto or London UK?
                            </b>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="question">
                            <>
                                <p>
                                    Yes! We welcome participants from all across the globe to participate.
                                    We will try our best to accommodate the event to your time zone.
                                    If you applied as an individual, we will try our best to match you with a team from a similar time zone to enable your team to better collaborate.
                                </p>
                                <p>
                                    We recognize that teams in East Asia may have their presentations scheduled in late nights.
                                    For those teams, we can provide the option to submit a pre-recorded presentation with a list of prepared questions for your team to respond to.
                                    We will evaluate this on a case-by-case basis - we encourage you to apply and we will find a way to accommodate once accepted.
                                </p>
                            </>
                        </Accordion.Collapse>
                    </Accordion>
                </Col>
                <Col md={6}>
                    <Accordion>
                        <Accordion.Toggle eventKey="question">
                            <b>
                                What if I'm interested but can't find a full team?
                            </b>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="question">
                            <p>
                                You can apply as an individual and if accepted, we will match you with other individual applications closer to the
                                Kickoff date. Hack the Globe is  team-based hackathon, so everyone will be formed into teams of 4 and we will do our
                                best to match you with a diverse team of skill sets, capabilities, and experience.
                            </p>
                        </Accordion.Collapse>
                    </Accordion>
                    <Accordion>
                        <Accordion.Toggle eventKey="question">
                            <b>
                                Can my team work on our solution during the 2 week ideation period?
                            </b>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="question">
                            <p>
                                We encourage teams to use this 2 week time to think through the themes and problem areas that
                                they would like to tackle. You are free to do research, refine your scope, and brainstorm
                                with your BCG mentor during this time, however you will not be allowed to begin any work on
                                the presentation materials or code itself.
                            </p>
                        </Accordion.Collapse>
                    </Accordion>
                    <Accordion>
                        <Accordion.Toggle eventKey="question">
                            <b>
                                Can I participate if I'm not in Eastern Standard Time?
                            </b>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="question">
                            <>
                                <p>
                                    Yes! We welcome participants from all across the globe to participate. We will try our best to accommodate
                                    the event and mentor chats to your time zone, however the preliminary, high-level schedule has already been
                                    set and can be found in the FAQs.
                                </p>
                                <p>
                                    If you applied as an individual, we will try our best to match you with a team from a similar time zone to
                                    enable your team to better collaborate.
                                </p>
                                <p>
                                    We recognize that some teams may have their presentation time in the middle of the night or early in the morning.
                                    For those teams, we will provide the option to submit a pre-recorded presentation with a list of prepared questions
                                    for your team to respond to. We will evaluate this on a case-by-case basis - we encourage you to apply and we
                                    will find a way to accommodate once accepted.
                                </p>
                            </>
                        </Accordion.Collapse>
                    </Accordion>
                    <Accordion>
                        <Accordion.Toggle eventKey="question">
                            <b>
                                What is the format of the final presentation?
                            </b>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="question">
                            <p>
                                At the end of the hacking weekend, each team will deliver a business pitch (typically done via Powerpoint), a short demo of their technical prototype, and answer Q&amp;A from a panel of judges.
                                Successful teams will continue onto the final round of judging with a new panel of top-tier judges.
                            </p>
                        </Accordion.Collapse>
                    </Accordion>
                    <Accordion>
                        <Accordion.Toggle eventKey="question">
                            <b>
                                What if I am not able to attend the kickoff due to time zone differences?
                            </b>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="question">
                            <p>
                                We encourage teams to tune in to our livestreams if possible.
                                We will also try our best to record the Kickoff for your team to view afterwards.
                                With that being said, we strongly encourage participation in as many of the live activities as possible to maximize your experience.
                            </p>
                        </Accordion.Collapse>
                    </Accordion>
                    <Accordion>
                        <Accordion.Toggle eventKey="question">
                            <b>
                                Will I be able to have a BCG mentor from my timezone to communicate with?
                            </b>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="question">
                            <p>
                                Yes! This year we are bringing in BCG mentors from all over the world.
                                We will aim to match your team with a BCG mentor from the same country as members of your team - or from the same region at the very least.
                            </p>
                        </Accordion.Collapse>
                    </Accordion>
                </Col>
            </Row>
            <br />
            <p>
                Any other questions? Don’t hesitate to contact us at
                {' '}
                <a href="mailto: hacktheglobe@globalspark.world">hacktheglobe@globalspark.world</a>
                !
                <br />
                <small>*Johnson & Johnson is a trademark of Johnson & Johnson and affiliated companies.</small>
            </p>
        </Container>
    </Container>
);

export default FAQComponent;
