import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

import { ROUTES } from './util/constants';

import Banner from './components/Banner';
import Footer from './components/Footer';
import Header from './components/Header';

import { firebaseInit } from './util/firebase';
import { reactGAInit } from './util/analytics';

import HomeComponent from './home/HomeComponent';
import HackTheGlobeComponent from './htg/HTGComponent';
import PanelComponent from './panel/PanelComponent';
import TeamComponent from './team/TeamComponent';
import CurriculumComponent from './curriculum/CurriculumComponent';
import LocalEventsComponent from './localEvents/LocalEventsComponent';
import PodcastComponent from './podcast/PodcastComponent';
import PodcastShowNotesComponent from './podcast/PodcastShowNotesComponent';
import CommunityComponent from './community/CommunityComponent';
import SICPComponent from './sicp/SICPComponent';

const App = () => {
    AOS.init();
    firebaseInit(() => {});
    reactGAInit();

    return (
        <Router>
            <Banner />
            <Header />
            <div className="gs-content">
                <Switch>
                    <Route path={ROUTES.HACK_THE_GLOBE} exact>
                        <HackTheGlobeComponent />
                    </Route>
                    <Route path={ROUTES.PANEL} exact>
                        <PanelComponent />
                    </Route>
                    <Route path={ROUTES.TEAM} exact>
                        <TeamComponent />
                    </Route>
                    <Route path={ROUTES.CURRICULUM} exact>
                        <CurriculumComponent />
                    </Route>
                    <Route path={ROUTES.LOCAL_EVENTS}>
                        <LocalEventsComponent />
                    </Route>
                    <Route path={ROUTES.PODCAST_EPISODE}>
                        <PodcastShowNotesComponent />
                    </Route>
                    <Route path={ROUTES.PODCAST}>
                        <PodcastComponent />
                    </Route>
                    <Route path={ROUTES.COMMUNITY} exact>
                        <CommunityComponent />
                    </Route>
                    <Route path={ROUTES.SICP} exact>
                        <SICPComponent />
                    </Route>
                    <Route path={ROUTES.LANDING}>
                        <HomeComponent />
                    </Route>
                </Switch>
            </div>
            <Footer />
        </Router>
    );
};

export default App;
